<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.endDate') }}</h3>
    <transition name="flixFadeIn">
      <div :key="end">
        <a class="flix-form-control flix-html-a" href="#"  v-if="!end" @click.prevent="setEnd()">---</a>
        <div v-if="end">
          <dayPicker :date="end" :callback="function(date){callback(date)}" />
          <div>
            <a class="flix-html-a flix-text-danger" href="#" @click.prevent="end = ''; callback(end)">{{ $t('message.delete') }}</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    dayPicker () { return import('@/components/assistent/components/assets/daypicker') }
  },
  props: { data: Object, callback: Function },
  data () {
    return {
      end: this.data.end
    }
  },
  watch: {
  },
  methods: {
    setEnd () {
      var d = new Date()
      d.setDate(d.getDate())
      d.setMonth(d.getMonth() + 2)
      this.end = d.getFullYear() + '-' + this.$getNullsBefore(d.getMonth() + 1) + '-' + this.$getNullsBefore(d.getDate()) + ' ' + this.$getNullsBefore(d.getHours()) + ':00'
      this.callback(this.end)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
